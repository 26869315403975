import '../../assets/styles/index.scss'
import 'swiper/swiper-bundle.min.css'
import Swiper, {Navigation, Pagination, Autoplay, Thumbs} from 'swiper';
import {BannerSwiper} from "@/js/page-utils";
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import {Watermark} from "@/js/utils";
gsap.registerPlugin(Draggable);

// 是的，Swiper 6之后要以这种方式注册插件，想不到吧卧槽
Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

window.onload = function () {

    const bannerSwiper = document.getElementById('banner');
    const bannerList = document.querySelectorAll('.banner_item');
    if(bannerSwiper){
        new Swiper(bannerSwiper, {
            autoplay: bannerList.length > 1,
            slidesPerView: 1,
            navigation: {
                prevEl: '.banner_action_left',
                nextEl: '.banner_action_right'
            },
            pagination: {
                el: '.banner_nav',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<div class="${className} banner_nav_item" ></div>`
                }
            },
        })
    }

    const modalElement = document.getElementById('modal');
    if(modalElement){
        Draggable.create(modalElement)
    }

    const GSDTList = document.querySelectorAll('.gsdt_carousel_body_item');

    const GSDTSwiper = new Swiper('#gsdt_swiper', {
        autoplay: GSDTList.length > 1,
        slidesPerView: 'auto',
        navigation: {
            // @ts-ignore
            prevEl: document.querySelector('.gsdt_carousel_left_arrow'),
            // @ts-ignore
            nextEl: document.querySelector('.gsdt_carousel_right_arrow')
        }
    })

    const HZHBSwiper = new Swiper('#hzhb_container', {
        autoplay: true,
        slidesPerView: 1,
        navigation: {
            // @ts-ignore
            prevEl: document.querySelector('.hzhb_container_left_icon'),
            // @ts-ignore
            nextEl: document.querySelector('.hzhb_container_right_icon')
        }
    })

    const modal = document.querySelector('#modal');
    const modalCloseBtn = document.querySelector('.modal_bottom_close');
    if (modal && modalCloseBtn) {
        modalCloseBtn.addEventListener('click', () => {
            modal.setAttribute('style', 'display: none');
        })
    }

}
